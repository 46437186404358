<template>
  <div
    @click="routeToNews()"
    style="margin: 30px 130px; display: flex; align-items: center"
  >
    <img style="width: 50px" src="../assets/arrowl.png" alt="" />
    К списку новостей
  </div>
  <div
    style="display: grid; justify-items: center; margin: 30px 130px; gap: 30px"
  >
    <p style="color: #ff4b00">
      {{ news.last_modified }}
    </p>
    <h1 style="line-height: 30px; text-align: center">
      {{ news.title }}
    </h1>
    <img style="width: 100%" :src="news.img" />
    <p style="padding: 0 130px" v-html="news.description"></p>
  </div>
</template>
<script>
export default {
  name: "history",
  data() {
    return {
      news: [],
    };
  },
  mounted() {
    this.getNews();
    // console.log(this.$route.params);
  },
  methods: {
    async getNews() {
      const res = await fetch(
        "https://cb96744.tmweb.ru/api/news/" + String(this.$route.params.id)
      );
      const finalRes = await res.json();
      this.news = finalRes;
      // console.log(finalRes);
    },
    routeToNews() {
      this.$router.push("/news");
    },
  },
};
</script>
<style scoped>
div {
  font-family: RFD-Regular;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
}
</style>
